import React from "react"
import PropTypes from "prop-types"
import { Modal } from "@winnin/shared.ui.modal"

const defaultStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.80)",
    overflowY: "overlay",
    zIndex: 999
  },
  content: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "40px",
    border: "none"
  }
}

const CenteredModal = ({ closeModal, children, className }) => {
  return (
    <Modal
      className={`CenteredModal ${className}`}
      onRequestClose={closeModal}
      customStyles={defaultStyles}
      isOpen
    >
      {children}
    </Modal>
  )
}

CenteredModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

export default CenteredModal
