import React from "react"
import PropTypes from "prop-types"
import { Tooltip } from "@winnin/shared.ui.tooltip"
import "./index.scss"
import classNames from "classnames"

const ListItem = ({ columns, className, ...props }) => {
  if (!columns?.length) return null

  const renderTooltip = (label, tooltipContent) => {
    const tooltipId = (Math.random() * 1000).toFixed()

    return (
      <Tooltip
        targetId={`column-tooltip-${tooltipId}-${label}`}
        TooltipContent={tooltipContent}
        containerClassName="column-tooltip-container"
      >
        {label}
      </Tooltip>
    )
  }

  return (
    <div className={classNames("ListItem", className)} {...props}>
      {columns.map(({ label, onClick, tooltipContent }, index) => {
        return (
          <div
            key={index}
            className={classNames("column-container", { clickable: !!onClick })}
            onClick={onClick ? onClick : null}
          >
            {tooltipContent ? (
              renderTooltip(label, tooltipContent)
            ) : (
              <div className="label">{label}</div>
            )}
          </div>
        )
      })}
    </div>
  )
}

const columnShape = {
  label: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  tooltipContent: PropTypes.node
}

ListItem.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape(columnShape)).isRequired,
  className: PropTypes.string
}

export default ListItem
