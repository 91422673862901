import React from "react"
import Clamp from "react-dotdotdot"
import { Header } from "@winnin/shared.ui.header"
import { Card } from "@winnin/shared.ui.card"

import humanizeLargeNumber from "../../utils/humanizeLargeNumber"

const PostListItem = ({ post, handlePostSelection, isSelected }) => {
  const {
    thumbnail,
    title,
    topics,
    tags,
    views,
    likes,
    provider,
    postUrl
  } = post
  const providersMetric = {
    fb: { value: likes, label: "Likes" },
    in: { value: views, label: "Views" },
    yt: { value: views, label: "Views" }
  }
  const { value, label } = providersMetric[provider]

  if (post)
    return (
      <Card className={`PostListItem${isSelected ? " selected" : ""}`}>
        <div className="thumbnail">
          <img src={thumbnail} alt={title} />
        </div>

        <div className="content">
          <a
            href={postUrl}
            className="post-link"
            target="_blank"
            rel="noopener noreferrer"
            title={title}
          >
            <Header weight="light" size="xs">
              <Clamp clamp={2}>
                {title || "This post does not have a title"}
              </Clamp>
            </Header>
          </a>

          <div className="lists">
            {!!topics.length && (
              <p>Topics: {topics.map(({ title }) => title).join(", ")}</p>
            )}

            {!!tags.length && <p>Tags: {tags.join(", ")}</p>}
          </div>
        </div>

        <div className="metric">
          <Header weight="regular" size="xxs">
            {humanizeLargeNumber(value)}
          </Header>
          <span className="metric-label">{label}</span>
        </div>

        <div className="select-button" onClick={handlePostSelection}>
          <Header weight="regular" size="xxs">
            {isSelected ? "Selected" : "Select"}
          </Header>
        </div>
      </Card>
    )

  return null
}

export default PostListItem
