import React from "react"
import PropTypes from "prop-types"
import { Button } from "@winnin/shared.ui.button"

import CenteredModal from "../CenteredModal"
import { queryStatus } from "../../consts/queryStatus"
import "./index.scss"

const QueryModerateModal = ({ history, location: { state } }) => {
  // consts
  const { backgroundPage, queryObj } = state

  // functions
  const closeModal = () => {
    history.push({
      pathname: backgroundPage.pathname
    })
  }

  const handleAction = ({ action }) => {
    const rootUrl = backgroundPage.pathname

    history.push({
      pathname: `${rootUrl}/${action}`,
      state: {
        backgroundPage: {
          pathname: rootUrl
        },
        queryObj
      }
    })
  }

  // rendering
  const renderContent = () => {
    const username = queryObj?.user?.name
    const topicTitle = queryObj?.topic?.title

    if (queryObj.status === queryStatus.rejected) {
      return (
        <>
          <p>
            <strong>{username}</strong>'s query from{" "}
            <strong>{topicTitle}</strong> was rejected for these reasons:
          </p>

          <p className="moderate-feedback">
            {queryObj.feedback || "No feedback provided."}
          </p>
        </>
      )
    } else {
      return <p>This query was approved.</p>
    }
  }

  return (
    <CenteredModal className="QueryModerateModal" closeModal={closeModal}>
      {renderContent()}

      <div className="moderate-actions">
        <Button
          appearance="link"
          onClick={() => handleAction({ action: "delete" })}
        >
          Delete query
        </Button>
        <Button onClick={() => handleAction({ action: "edit" })}>
          Edit query
        </Button>
      </div>
    </CenteredModal>
  )
}

QueryModerateModal.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default QueryModerateModal
