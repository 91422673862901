import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { compose, withApollo } from "react-apollo"
import InfiniteScroll from "react-infinite-scroller"
import { useQuery, useLazyQuery } from "@apollo/react-hooks"
import { Button } from "@winnin/shared.ui.button"
import { Input } from "@winnin/shared.ui.input"
import { Loading } from "@winnin/shared.ui.loading"

import AppHeader from "../../components/AppHeader"
import Select from "../../components/Select"
import ListHeader from "../../components/ListHeader"
import ListItem from "../../components/ListItem"
import {
  SUBTOPICS_QUERY,
  CATEGORIES_QUERY,
  TOPICS_QUERY,
  TOPICS_BY_CATEGORY_QUERY
} from "./query.js"
import { LANGUAGE_OPTIONS, PAGINATION } from "../../consts"
import ToastStore from "../../stores/ToastStore"
import "./index.scss"

const defaultLanguage = LANGUAGE_OPTIONS[0]
const defaultCategory = { label: "All categories", value: "" }
const defaultTopic = { label: "All topics", value: "" }

const Subtopics = ({ client, history, ...props }) => {
  // local state
  const [hasMore, setHasMore] = useState(true)
  const [searchTerm, setSearchTerm] = useState("")
  const [language, setLanguage] = useState(defaultLanguage)
  const [categoryOptions, setCategoryOptions] = useState([defaultCategory])
  const [selectedCategory, setSelectedCategory] = useState(defaultCategory)
  const [topicOptions, setTopicOptions] = useState([defaultTopic])
  const [selectedTopic, setSelectedTopic] = useState(defaultTopic)

  // queries and mutations
  const {
    data: subtopicsData,
    loading: subtopicsLoading,
    error: subtopicsError,
    networkStatus: subtopicsStatus,
    fetchMore: subtopicsFetchMore,
    refetch: subtopicsRefetch
  } = useQuery(SUBTOPICS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    client,
    variables: {
      offset: 0,
      limit: PAGINATION.PAGE_SIZE,
      sort: { field: "title", order: "asc" }
    },
    onCompleted: ({ listSubtopics: { entities, rows } }) => {
      const subtopicsCount = entities.length
      const limit = PAGINATION.PAGE_SIZE
      const hasMore = subtopicsCount >= limit && subtopicsCount < rows

      setHasMore(hasMore)
    }
  })
  const [loadCategories, { loading: categoriesLoading }] = useLazyQuery(
    CATEGORIES_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      client,
      onCompleted: ({ allTopicGroups }) => {
        const categoryOptions = allTopicGroups.map(
          ({ title, topicGroupId }) => ({
            label: title,
            value: topicGroupId
          })
        )

        setCategoryOptions([defaultCategory, ...categoryOptions])
      },
      onError(e) {
        console.error("SubtopicListPage @ loadCategories >>>>>", e)

        ToastStore.clearToasts()
        ToastStore.addToast({
          title: "Oops! Error loading categories. Please try again.",
          id: "categories-error"
        })
      }
    }
  )
  const [loadTopics, { loading: topicsLoading }] = useLazyQuery(TOPICS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    client,
    onCompleted: ({ allTopics }) => {
      const topicOptions = allTopics.entities.map(({ title, topicId }) => ({
        label: title,
        value: topicId
      }))

      setTopicOptions([defaultTopic, ...topicOptions])
    },
    onError(e) {
      console.error("SubtopicListPage @ loadTopics >>>>>", e)

      ToastStore.clearToasts()
      ToastStore.addToast({
        title: "Oops! Error loading topics. Please try again.",
        id: "topics-error"
      })
    }
  })
  const [
    loadTopicsByCategory,
    { loading: topicsByCategoryLoading }
  ] = useLazyQuery(TOPICS_BY_CATEGORY_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    client,
    onCompleted: ({ topicByGroup }) => {
      const topicOptions = topicByGroup.map(({ title, topicId }) => ({
        label: title,
        value: topicId
      }))

      setTopicOptions([defaultTopic, ...topicOptions])
    },
    onError(e) {
      console.error("SubtopicListPage @ loadTopicsByCategory >>>>>", e)

      ToastStore.clearToasts()
      ToastStore.addToast({
        title: "Oops! Error loading topics. Please try again.",
        id: "topics-by-category-error"
      })
    }
  })

  // effects
  useEffect(() => {
    // on language filter change, reset other filters
    if (language.value === "none") {
      setCategoryOptions([defaultCategory])
      setTopicOptions([defaultTopic])
    } else {
      const queryLanguage = language.value === "global" ? "all" : language.value

      loadCategories({
        variables: {
          language: queryLanguage
        }
      })

      loadTopics({
        variables: {
          language: queryLanguage
        }
      })
    }

    setSelectedCategory(defaultCategory)
    setSelectedTopic(defaultTopic)
  }, [language])

  useEffect(() => {
    // on category filter change, fetch topics by category
    if (language.value !== "none") {
      if (selectedCategory.value === "") {
        const queryLanguage =
          language.value === "global" ? "all" : language.value

        loadTopics({
          variables: {
            language: queryLanguage
          }
        })
      } else {
        loadTopicsByCategory({
          variables: {
            groupIds: [selectedCategory.value]
          }
        })
      }

      setSelectedTopic(defaultTopic)
    }
  }, [selectedCategory])

  useEffect(() => {
    // on topic filter change, fetch subtopics
    if (selectedTopic.value === "") {
      const queryTopicIds = getQueryTopicIds()

      subtopicsRefetch({
        topicIds: queryTopicIds
      })
    } else {
      subtopicsRefetch({
        topicIds: [selectedTopic.value]
      })
    }
  }, [selectedTopic])

  useEffect(() => {
    // on topic options change, fetch subtopics
    const queryTopicIds = getQueryTopicIds()

    subtopicsRefetch({
      topicIds: queryTopicIds
    })
  }, [topicOptions])

  // functions
  const getQueryTopicIds = () => {
    const queryTopicIds = topicOptions
      .filter(topic => Number.isInteger(topic.value))
      .map(topic => topic.value)

    return queryTopicIds
  }

  const handleInputChange = e => {
    setSearchTerm(e.target.value)
  }

  const handleSearch = e => {
    e?.preventDefault() && e.preventDefault()

    subtopicsRefetch({
      query: {
        title: searchTerm
      }
    })
  }

  const loadMore = async () => {
    if (subtopicsLoading || subtopicsError || !hasMore) return

    const variables = {
      offset: subtopicsData?.listSubtopics?.entities?.length
    }

    try {
      await subtopicsFetchMore({
        variables,
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult?.listSubtopics?.entities?.length) {
            return prev
          }

          if (
            fetchMoreResult.listSubtopics.entities.length < PAGINATION.PAGE_SIZE
          ) {
            setHasMore(false)
          }

          return Object.assign({}, prev, {
            listSubtopics: {
              ...prev.listSubtopics,
              entities: [
                ...prev.listSubtopics.entities,
                ...fetchMoreResult.listSubtopics.entities
              ]
            }
          })
        }
      })
    } catch (e) {
      console.error("SubtopicListPage @ loadMore >>>>>", e)
    }
  }

  // consts and functions used in render
  const isFilterDisabled = language.value === "none"
  const subtopics = subtopicsData?.listSubtopics?.entities ?? []
  const subtopicsCount = subtopicsData?.listSubtopics?.rows ?? "..."
  const subtopicsListHeaders = [
    { label: `Subtopic (${subtopicsCount})` },
    { label: "Topic" },
    { label: "Category" },
    { label: "Upload videos" },
    {
      label: "Upload date",
      tooltipContent: (
        <>
          This metric is relative at
          <br />
          30 days, return to zero in
          <br />
          the end of each month
        </>
      )
    },
    { label: "Total videos" }
  ]

  const renderSubtopicList = () => {
    if (subtopicsError) return <p>Error loading subtopics :(</p>
    if (subtopicsLoading && subtopicsStatus !== 3) return <Loading />
    if (!subtopics?.length) return <p>No subtopics found.</p>

    return (
      <InfiniteScroll
        pageStart={0}
        loadMore={loadMore}
        loader={
          <div key={Date.now()}>
            <Loading />
          </div>
        }
        hasMore={hasMore}
        initialLoad={false}
        className="subtopics-list"
      >
        {subtopics?.map(({ subtopicId, title, term, topic }, index) => {
          const columns = [
            {
              label: title,
              tooltipContent: term,
              onClick: () => history.push(`/subtopics/edit/${subtopicId}`)
            },
            {
              label: topic.title,
              onClick:
                language.value !== "none"
                  ? () =>
                      setSelectedTopic({
                        label: topic.title,
                        value: topic.topicId
                      })
                  : null
            },
            {
              label: topic?.category?.title || "---",
              onClick:
                language.value !== "none" && topic?.category
                  ? () =>
                      setSelectedCategory({
                        label: topic.category.title,
                        value: topic.category.topicGroupId
                      })
                  : null
            },
            { label: "---" },
            { label: "---" },
            { label: "---" }
          ]

          return (
            <ListItem
              key={`${title}-${index}`}
              columns={columns}
              className="subtopics-item"
            />
          )
        })}
      </InfiniteScroll>
    )
  }

  return (
    <>
      <AppHeader />
      <div className="SubtopicsPage">
        <div className="page-header">
          <Link to="/subtopics/new" className="create-new-button">
            <Button icon="add">Create new</Button>
          </Link>

          <div className="subtopic-filters">
            <form onSubmit={handleSearch} className="subtopic-search-form">
              <Input
                onChange={handleInputChange}
                value={searchTerm}
                placeholder="Search for a Subtopic"
                className="subtopic-search-input"
              />
            </form>
            <Select
              label="Language"
              options={LANGUAGE_OPTIONS}
              value={language}
              onChange={setLanguage}
            />
            <Select
              label="Categories"
              value={selectedCategory}
              options={categoryOptions}
              isLoading={categoriesLoading}
              isDisabled={isFilterDisabled}
              onChange={setSelectedCategory}
            />
            <Select
              label="Topics"
              value={selectedTopic}
              options={topicOptions}
              isLoading={topicsLoading || topicsByCategoryLoading}
              isDisabled={isFilterDisabled}
              onChange={setSelectedTopic}
            />
          </div>
        </div>

        <div className="page-content">
          <ListHeader
            headers={subtopicsListHeaders}
            className="subtopics-list-header"
          />
          {renderSubtopicList()}
        </div>
      </div>
    </>
  )
}

export default compose(withApollo)(Subtopics)
