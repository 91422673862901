import React from "react"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import { Icon } from "@winnin/shared.ui.icon"
import { Tooltip } from "@winnin/shared.ui.tooltip"

import { PROVIDERS } from "../../consts"
import { humanizeLargeNumber } from "../../utils"
import Clamp from "react-dotdotdot"
import PropTypes from "prop-types"
import "./index.scss"

dayjs.extend(relativeTime)

const SimplePostCard = ({
  thumbnail,
  title,
  creatorTitle,
  topics,
  publishedAt,
  provider,
  engagement,
  views,
  postId,
  postUrl
}) => (
  <article className="SimplePostCard">
    <div className="thumbnail">
      <img src={thumbnail} alt={title} />
    </div>

    <header className="post-header">
      <div>
        <Tooltip
          targetId={`post-title-tooltip-${postId}`}
          TooltipContent={title}
          containerClassName="post-title"
        >
          <Clamp clamp={2}>
            <a
              href={postUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="post-link"
            >
              {title}
            </a>
          </Clamp>
        </Tooltip>

        <p className="creator-title">
          <span>by</span> {creatorTitle}
        </p>

        <p className="topics">
          {topics.length ? (
            <>
              <span>Topic: </span>
              {topics.map(({ title }) => title).join(", ")}
            </>
          ) : (
            <span>No topics</span>
          )}
        </p>
      </div>

      <p className="published">{dayjs(publishedAt).from(dayjs())}</p>
    </header>

    <footer className="post-footer">
      <div className={`provider ${provider}`}>
        <Icon icon={PROVIDERS[provider].NAME} />
      </div>

      <div className="metrics">
        <p>Engag.: {humanizeLargeNumber(engagement)}</p>
        <p>Views: {humanizeLargeNumber(views)}</p>
      </div>
    </footer>
  </article>
)

const topicShape = {
  title: PropTypes.string
}

SimplePostCard.propTypes = {
  thumbnail: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  creatorTitle: PropTypes.string.isRequired,
  topics: PropTypes.arrayOf(PropTypes.shape(topicShape)).isRequired,
  publishedAt: PropTypes.string.isRequired,
  provider: PropTypes.string.isRequired,
  engagement: PropTypes.number.isRequired,
  views: PropTypes.number.isRequired,
  postId: PropTypes.string.isRequired,
  postUrl: PropTypes.string.isRequired
}

export default SimplePostCard
