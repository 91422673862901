import React, { Component } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Loading } from "@winnin/shared.ui.loading"

import "./index.scss"

class Wizard extends Component {
  setStep = (currentStep, callbackKey) => {
    const { children } = this.props
    const callback = this.props[callbackKey]

    if (currentStep >= 1 && currentStep <= children.length) {
      if (callback) callback(currentStep)
    }
  }

  nextStep = () => {
    const { currentStep } = this.props
    this.setStep(currentStep + 1, "onNext")
  }

  previousStep = () => {
    const { currentStep } = this.props
    this.setStep(currentStep - 1, "onPrev")
  }

  renderChilds = props => {
    const { children } = this.props
    return React.Children.map(children, (child, i) => {
      const childProps = {
        className: classNames("step", {
          "current-step": i + 1 === props.currentStep
        }),
        ...props
      }

      return React.cloneElement(child, childProps)
    })
  }

  render() {
    const { children, loading, currentStep } = this.props
    const { nextStep, previousStep } = this
    const totalSteps = children.length

    return (
      <div className="wizard-container">
        {loading ? (
          <Loading />
        ) : (
          this.renderChilds({
            currentStep,
            totalSteps,
            nextStep,
            previousStep
          })
        )}
      </div>
    )
  }
}

Wizard.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  currentStep: PropTypes.number
}
Wizard.defaultProps = {
  children: null,
  loading: false,
  currentStep: 0
}

export default Wizard
