import React from "react"
import { useMutation } from "@apollo/react-hooks"
import { Button } from "@winnin/shared.ui.button"
import { Header } from "@winnin/shared.ui.header"

import CenteredModal from "../CenteredModal"
import { client } from "../../ApolloProvider"
import { SUBTOPIC_DELETE_MUTATION } from "./query"
import ToastStore from "../../stores/ToastStore"
import "./index.scss"

const SubtopicDeleteModal = ({ history }) => {
  const { name, subtopicId } = history?.location?.state

  const [subtopicDelete] = useMutation(SUBTOPIC_DELETE_MUTATION, {
    client,
    onError(e) {
      console.error("SubtopicDeleteModal @ subtopicDelete >>>>>", e)
    },
    onCompleted({ deleteSubtopic: { success } }) {
      if (success) {
        history.push({
          pathname: "/subtopic/success/delete",
          state: { backgroundPage: { pathname: "/subtopics" }, name }
        })
      } else {
        ToastStore.clearToasts()
        ToastStore.addToast({
          title: `Oops! The subtopic was not deleted. Please try again.`,
          id: "delete-error"
        })
      }
    }
  })

  const handleDelete = () => {
    const variables = {
      id: Number(subtopicId)
    }

    subtopicDelete({ variables })
  }

  const closeModal = () => {
    history.goBack()
  }

  return (
    <CenteredModal className="SubtopicDeleteModal" closeModal={closeModal}>
      <Header className="title" size="md">
        Are you sure?
      </Header>

      <p className="message">
        If you confirm the exclusion some board can break the experience, do you
        really want to permanently delete the <strong>{name}</strong> subtopic?
      </p>

      <div className="buttons">
        <Button onClick={handleDelete} appearance="outline">
          Delete subtopic
        </Button>
        <Button onClick={closeModal}>Cancel</Button>
      </div>
    </CenteredModal>
  )
}

export default SubtopicDeleteModal
