import React from "react"
import { Header } from "@winnin/shared.ui.header"
import { Button } from "@winnin/shared.ui.button"

import CenteredModal from "../CenteredModal"
import "./index.scss"

const TopicSuccessModal = ({ history, location: { state } }) => {
  const { backgroundPage } = state
  const closeModal = () =>
    history.push({
      pathname: backgroundPage.pathname,
      state: { refetch: true }
    })

  return (
    <CenteredModal className="TopicSuccessModal" closeModal={closeModal}>
      <Header className="title" size="md">
        Saved change
      </Header>

      <p className="message">This change will be made into 7 days</p>

      <div className="buttons">
        <Button onClick={closeModal}>Back to topic</Button>
      </div>
    </CenteredModal>
  )
}

export default TopicSuccessModal
