import React, { Component } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Icon } from "@winnin/shared.ui.icon"

import "./index.scss"

class Thumbnail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fail: false
    }
  }

  componentDidUpdate(PrevProps) {
    if (PrevProps.src !== this.props.src) {
      this.setState({ fail: false })
    }
  }

  onError = () => {
    this.setState({ fail: true })
  }

  renderImage() {
    const { src, alt, href, width, height } = this.props
    const { fail } = this.state

    if (!src || src === "" || fail)
      return <Icon className="thumbnail-no-image" icon="no-image" />

    if (href && src)
      return (
        <a href={href}>
          <img
            src={src}
            loading="lazy"
            width={width}
            height={height}
            onError={this.onError}
            alt={alt}
          />
        </a>
      )

    if (src)
      return (
        <img
          src={src}
          loading="lazy"
          width={width}
          height={height}
          onError={this.onError}
          alt={alt}
        />
      )
  }

  render() {
    const {
      className,
      src,
      alt,
      href,
      provider,
      trending,
      ...props
    } = this.props
    const containerClass = classNames(
      "thumbnail-component-container",
      className
    )

    return (
      <div className={containerClass} {...props}>
        {this.renderImage()}
      </div>
    )
  }
}

Thumbnail.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string,
  href: PropTypes.string
}

Thumbnail.defaultProps = {
  alt: "",
  width: null,
  height: null
}

export default Thumbnail
