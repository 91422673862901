import React, { useState, useEffect } from "react"
import { compose, withApollo } from "react-apollo"
import { Loading } from "@winnin/shared.ui.loading"
import { Header as Heading } from "@winnin/shared.ui.header"
import { Button } from "@winnin/shared.ui.button"

import InfiniteScroll from "react-infinite-scroll-component"
import Input from "../../components/Input"
import Select from "../../components/Select"
import CardCreator from "../../components/CardCreator"
import {
  useCreatorQuery,
  useBlockCreator,
  useBlockCreatorList,
  usePagination,
} from "../../hooks"
import { LANGUAGE_OPTIONS, PAGINATION } from "../../consts"
import "./index.scss"

const languagesOptionsCreatorsFilter = [
  {
    label: "All",
    value: ["por", "eng", "spa"],
  },
  {
    label: "Portuguese",
    value: "por",
  },
  {
    label: "English",
    value: "eng",
  },
  {
    label: "Spanish",
    value: "spa",
  },
]

const CreatorListHeaders = () => {
  return (
    <div className="creator-list-headers">
      <div className="creator">
        <Heading size="xxs">Creator name</Heading>
      </div>
      <div className="language">
        <Heading size="xxs">Blocked languages</Heading>
      </div>
      <div className="topics">
        <Heading size="xxs">Blocked topics</Heading>
      </div>
    </div>
  )
}

const TopicListItem = ({ topic }) => {
  const {
    languages,
    creator: { title, thumbnail },
    topics,
  } = topic

  return (
    <div className="creator-list-item">
      <div className="creator-item flex">
        <img src={thumbnail} alt={title} />
        <p>{title}</p>
      </div>
      <div className="languages-item">{languages.join()}</div>
      <div className="topics-item">
        {topics.map((topic) => topic.title).join()}
      </div>
    </div>
  )
}

const BlockListPage = ({ client }) => {
  const limit = PAGINATION.PAGE_SIZE
  const [searchInputValue, setSearchInputValue] = useState("")
  const [searchTerm, setSearchTerm] = useState("")
  const [isRequestBlockCreator, setRequestToBlockCreator] = useState(false)
  const [language, setLanguage] = useState(LANGUAGE_OPTIONS[0])
  const [searchCreatorInputValue, setSearchCreatorInputValue] = useState("")
  const [searchCreatorTermList, setSearchCreatorTermList] = useState("")
  const { offset, setPage, page } = usePagination({ limit })

  const {
    blockCreators,
    noBlockCreators,
    loadingBlockCreators,
    filteredCreators,
    languageListFilterCreator,
    setLanguageListFilterCreator,
    filterTopics,
    topicsFilterListCreator,
    setTopicsFilterListCreator,
    hasMore,
  } = useBlockCreatorList({
    client,
    searchCreatorTermList,
    languagesOptionsCreatorsFilter,
    offset,
    limit,
  })

  const {
    infosCreator,
    loadingCreator,
    noResults,
    creatorDontHaveTopic,
    allTopics,
    setTopics,
    topics,
    creatorIdProvider,
  } = useCreatorQuery({
    client,
    searchTerm,
    blockCreators,
  })

  const {
    isBlockCreatorTopic,
    isBlockCreatorLanguage,
    setBlockCreatorLanguage,
    setBlockCreatorTopic,
    isLoadingTopic,
    isLoadingLanguage,
  } = useBlockCreator({
    topics,
    language,
    isRequestBlockCreator,
    client,
    creatorIdProvider,
  })

  const refreshPage = () => {
    window.location.reload()
  }

  const handleLoadMore = () => {
    if (loadingBlockCreators || loadingCreator) return
    setPage(page + 1)
  }

  useEffect(() => {
    setBlockCreatorLanguage(false)
    setBlockCreatorTopic(false)
  }, [searchTerm])

  return (
    <div className="Blocklist-Page">
      <div className="container-search">
        <Input
          icon="search"
          className="search-input"
          placeholder={`Search for a creator url`}
          onChange={setSearchInputValue}
          value={searchInputValue}
          onSubmit={() => setSearchTerm(searchInputValue)}
          disabled={loadingBlockCreators}
        />
        <div className="container-result">
          {loadingCreator && <Loading className="loading" small />}

          {infosCreator && !isBlockCreatorTopic && !isBlockCreatorLanguage && (
            <>
              <CardCreator className="CardCreator" infos={infosCreator} />
              <div className="container-add-creator-blocklist">
                <Heading size="sm">Add creator to the blocklist</Heading>
                <div className="filters">
                  <Select
                    className="topic-language"
                    label="Select languages to block"
                    options={LANGUAGE_OPTIONS}
                    value={language}
                    onChange={setLanguage}
                  />
                  {!creatorDontHaveTopic && (
                    <Select
                      isMulti
                      className="topic-filter-multiselect"
                      label="Select topics to block"
                      options={allTopics}
                      value={topics}
                      onChange={setTopics}
                    />
                  )}
                  {isLoadingTopic || isLoadingLanguage ? (
                    <Loading />
                  ) : (
                    <>
                      <Button onClick={refreshPage} appearance="link">
                        Cancel
                      </Button>
                      <Button
                        onClick={() =>
                          setRequestToBlockCreator(!isRequestBlockCreator)
                        }
                      >
                        Save
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
          {noResults && <p className="error">No Results</p>}
        </div>
      </div>
      <div className="result-container">
        {blockCreators?.length ? (
          <div className="filter-container">
            <Input
              icon="search"
              className="search-result-input"
              placeholder={`Search for a creator name`}
              onChange={setSearchCreatorInputValue}
              value={searchCreatorInputValue}
              onSubmit={() => setSearchCreatorTermList(searchCreatorInputValue)}
            />
            <Select
              className="topic-result-language"
              label="Blocked language"
              options={languagesOptionsCreatorsFilter}
              value={languageListFilterCreator}
              onChange={setLanguageListFilterCreator}
            />
            <Select
              className="result-topics"
              label="Blocked topic"
              options={filterTopics}
              value={topicsFilterListCreator}
              onChange={setTopicsFilterListCreator}
            />
          </div>
        ) : null}
        <div className="count">
          <Heading size="md">{`${blockCreators.length} blocked creators`}</Heading>
        </div>
        {isBlockCreatorTopic && (
          <p className="success">Topic blocked with success!</p>
        )}
        {isBlockCreatorLanguage && (
          <p className="success">Language blocked with success!</p>
        )}
        <CreatorListHeaders />
        {blockCreators && (
          <InfiniteScroll
            loader={
              <div className="loading-videos-grid">
                <Loading />
              </div>
            }
            dataLength={blockCreators.length}
            next={handleLoadMore}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
            hasMore={hasMore}
          >
            {filteredCreators.map((blockTopic) => (
              <TopicListItem
                key={blockTopic.creator.title}
                topic={blockTopic}
              />
            ))}
          </InfiniteScroll>
        )}
        {noBlockCreators && <p className="error">No creator blocked!</p>}
      </div>
    </div>
  )
}

export default compose(withApollo)(BlockListPage)
