import React, { useState, useEffect } from "react"
import { compose, withApollo } from "react-apollo"
import InfiniteScroll from "react-infinite-scroller"
import { Header } from "@winnin/shared.ui.header"
import { Loading } from "@winnin/shared.ui.loading"
import { Button } from "@winnin/shared.ui.button"

import AppHeader from "../../components/AppHeader"
import Input from "../../components/Input"
import Select from "../../components/Select"
import VideoCard from "../../components/VideoCard"
import searchCircle from "../../assets/search-circle.svg"
import Insights from "../../frontend_commons"
import { Redirect } from "react-router-dom"
import { useCategoriesAndTopicsSelector } from "../../hooks"
import { SAMPLE_QUALITY_COLORS } from "../../consts"
import useVideoSearch from "../../hooks/useVideoSearch"
import usePagination from "../../hooks/usePagination"
import useSubtopicCreation from "../../hooks/useSubtopicCreation"
import useSampleQuality from "../../hooks/useSampleQuality"
import "./index.scss"

const formatPercent = v => (Math.abs(v) * 100).toFixed(1) + "%"
const { Format } = Insights.Utils

const dateOffset = Date.now() - 1000 * 60 * 60 * 24 * 365

const FIXED_PROVIDER_OPTIONS = [
  {
    label: "Instagram",
    value: "in"
  },
  {
    label: "Youtube",
    value: "yt"
  },
  {
    label: "Facebook",
    value: "fb"
  }
]

function VideoListWithBlankState({
  rows,
  videos,
  handleLoadMore,
  hasMore,
  loadingVideos,
  noResults
}) {
  if (noResults)
    return (
      <div className="search-blank-state">
        <img src={searchCircle} alt="Search for videos" />
        <Header>No Results Found :(</Header>
      </div>
    )
  return (
    <>
      <div className="count">
        <Header size="md">
          {rows ? `${Format.Number.Human(rows)} Videos` : ""}
        </Header>
      </div>
      <div className="video-grid-w">
        {loadingVideos && !videos.length ? (
          <Loading />
        ) : (
          <InfiniteScroll
            pageStart={0}
            loadMore={handleLoadMore}
            loader={
              <div className="loading-videos-grid">
                <Loading />
              </div>
            }
            hasMore={hasMore}
            initialLoad={false}
            className="video-grid-wrapper"
          >
            {videos.map((video, i) => (
              <VideoCard
                key={`${video.postId}${i}`}
                index={`${video.postId}${i}`}
                video={video}
              />
            ))}
          </InfiniteScroll>
        )}
      </div>
    </>
  )
}

const SubtopicCreatePage = ({ client }) => {
  const limit = 20
  const [searchInputValue, setSearchInputValue] = useState("")
  // searchTerm is only changed when search action is commited
  const [searchTerm, setSearchTerm] = useState("")
  const [subtopicTitle, setSubtopicTitle] = useState("")
  const { offset, setPage } = usePagination({ limit })

  const {
    language,
    setLanguage,
    languageOptions,
    loadingCategories,
    loadingTopics,
    categoryOptions,
    topicOptions,
    selectedCategory,
    selectCategory,
    selectedTopic,
    selectTopic,
    selectedTopicIds
  } = useCategoriesAndTopicsSelector({
    client
  })

  // this is to ensure we make the search request when we change topics
  useEffect(() => {
    if (searchInputValue) setSearchTerm(searchInputValue)
  }, [selectedTopicIds])
  const {
    sampleQualityLabel,
    sampleQuality,
    loadingSampleQuality,
    engagement
  } = useSampleQuality({ client, term: searchTerm, topicId: selectedTopicIds })

  const { videos, rows, loadingVideos, hasMore, noResults } = useVideoSearch({
    client,
    searchTerm,
    minEngagement: engagement,
    publishedAt: { gt: dateOffset },
    topicIds: selectedTopicIds,
    limit,
    offset,
    providers: FIXED_PROVIDER_OPTIONS,
    sortBy: {}
  })

  const handleLoadMore = p => {
    if (loadingVideos) return
    setPage(p)
  }

  const shouldRenderBlankState = !searchTerm && !videos.length

  const {
    createSubtopic,
    isSaved,
    loadingSubtopicCreation
  } = useSubtopicCreation({
    client,
    name: subtopicTitle,
    topicId: selectedTopicIds,
    term: searchTerm
  })

  if (isSaved) return <Redirect to="/subtopics" />
  return (
    <>
      <AppHeader />
      <div className="SubtopicCreatePage">
        <div className="status-bar">
          <div className="controls">
            <div className="filters">
              <Select
                label="Topics Language"
                options={languageOptions}
                value={language}
                onChange={setLanguage}
              />
              <Select
                label="Categories"
                value={selectedCategory}
                options={categoryOptions}
                onChange={selectCategory}
                isDisabled={loadingCategories}
              />
              <Select
                label="Topics"
                options={topicOptions}
                isDisabled={loadingTopics}
                value={selectedTopic}
                onChange={selectTopic}
              />
              <Input
                label="Subtopic Title"
                placeholder="Give this subtopic a name"
                onChange={setSubtopicTitle}
                value={subtopicTitle}
              />
            </div>
            <div className="subtopic-controls">
              {(sampleQualityLabel || loadingSampleQuality) && (
                <div className="sample-quality">
                  <Header weight="regular" size="xxs">
                    Sample Quality
                  </Header>
                  {loadingSampleQuality ? (
                    <Loading />
                  ) : (
                    <div
                      style={{
                        color: SAMPLE_QUALITY_COLORS[sampleQualityLabel]
                      }}
                      className="sample-quality-label"
                    >
                      {sampleQualityLabel}
                      <span>
                        {sampleQuality && formatPercent(sampleQuality)}
                      </span>
                    </div>
                  )}
                </div>
              )}
              {loadingSubtopicCreation ? (
                <Loading />
              ) : (
                <Button
                  disabled={
                    isSaved ||
                    !selectedTopicIds.length ||
                    selectedTopicIds.length > 1 ||
                    !searchTerm ||
                    !subtopicTitle ||
                    noResults ||
                    !videos.length
                  }
                  onClick={createSubtopic}
                >
                  Save
                </Button>
              )}
            </div>
          </div>
          <div className="input-term">
            <Input
              icon="search"
              placeholder={`You can use " ", (  ), OR, AND or NOT to improve your search`}
              onChange={setSearchInputValue}
              value={searchInputValue}
              onSubmit={() => setSearchTerm(searchInputValue)}
            />
          </div>
        </div>
        <div className="page-results">
          {shouldRenderBlankState ? (
            <div className="search-blank-state">
              <img src={searchCircle} alt="Search for videos" />
              <Header>Start by typing a keyword</Header>
            </div>
          ) : (
            <VideoListWithBlankState
              rows={rows}
              videos={videos}
              handleLoadMore={handleLoadMore}
              hasMore={hasMore}
              loadingVideos={loadingVideos}
              noResults={noResults}
            />
          )}
          {!hasMore && <Header size="lg">End of results</Header>}
        </div>
      </div>
    </>
  )
}

export default compose(withApollo)(SubtopicCreatePage)
