import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Button } from "@winnin/shared.ui.button"

import Select from "../../components/Select"
import Input from "../../components/Input"
import "./index.scss"

const TopicEditHeader = ({
  handleInputChange,
  handleSave,
  onReset,
  initialName,
  isResetDisabled,
  isSaveDisabled,
  categoryTitle,
  topicLanguage,
  inputState,
  role
}) => {
  const [nameInputRef, setNameInputRef] = useState(null)

  useEffect(() => {
    if (nameInputRef) nameInputRef.current.value = inputState.name
  }, [nameInputRef])

  const resetInputs = () => {
    nameInputRef.current.value = initialName

    onReset && onReset()
  }

  return (
    <div className="TopicEditHeader">
      <div className="top">
        <div className="inputs">
          <Input
            className="name-input"
            name="name"
            label="Topic name"
            getRef={inputRef => setNameInputRef(inputRef)}
            onChange={handleInputChange}
            disabled={role.value === "admin"}
          />

          <Select
            value={{ label: categoryTitle }}
            label="Category"
            isDisabled
          />

          <Select
            value={{ label: topicLanguage }}
            label="Language"
            isDisabled
          />
        </div>

        <div className="buttons">
          <Button
            className="reset-button"
            appearance="outline"
            disabled={isResetDisabled || role.value === "admin"}
            onClick={resetInputs}
          >
            Reset
          </Button>
          <Button
            className="save-button"
            disabled={isSaveDisabled || role.value === "admin"}
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  )
}

const inputStateShape = {
  name: PropTypes.string.isRequired
}

const roleShape = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  priority: PropTypes.number.isRequired
}

TopicEditHeader.propTypes = {
  handleInputChange: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  initialName: PropTypes.string.isRequired,
  isResetDisabled: PropTypes.bool.isRequired,
  isSaveDisabled: PropTypes.bool.isRequired,
  categoryTitle: PropTypes.string.isRequired,
  topicLanguage: PropTypes.string.isRequired,
  inputState: PropTypes.shape(inputStateShape).isRequired,
  role: PropTypes.shape(roleShape).isRequired
}

export default TopicEditHeader
