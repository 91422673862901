import React from "react"
import { Button } from "@winnin/shared.ui.button"
import { Header } from "@winnin/shared.ui.header"

import CenteredModal from "../CenteredModal"
import "./index.scss"

const SubtopicSuccessModal = ({
  history,
  match: { params },
  location: { state }
}) => {
  const { name, backgroundPage } = state
  const { action } = params
  const modalContent = {
    delete: {
      title: "Sucessful Delete",
      message: `${name} was deleted`,
      buttonText: "Back to subtopics page",
      closeModal: () => history.push({ pathname: backgroundPage.pathname })
    },
    change: {
      title: "Saved change",
      message: "This change will be made into 7 days",
      buttonText: "Back to subtopic",
      closeModal: () =>
        history.push({
          pathname: backgroundPage.pathname,
          state: { refetch: true }
        })
    }
  }

  const renderModal = ({ title, message, buttonText, closeModal }) => {
    return (
      <CenteredModal className="SubtopicSuccessModal" closeModal={closeModal}>
        <Header className="title" size="md">
          {title}
        </Header>

        <p className="message">{message}</p>

        <div className="buttons">
          <Button onClick={closeModal}>{buttonText}</Button>
        </div>
      </CenteredModal>
    )
  }

  return renderModal(modalContent[action])
}

export default SubtopicSuccessModal
