import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Card } from "@winnin/shared.ui.card"

import "./index.scss"

const ProgressBullets = ({ currentStep, totalSteps }) => {
  const bullets = []
  let count = 0

  while (count < totalSteps) {
    const bulletClass = classNames("bullet", {
      active: currentStep === count + 1
    })
    bullets.push(<div className={bulletClass} key={`bullet-${count}`} />)
    count += 1
  }

  return <div className="progress-bullets">{bullets}</div>
}

const StepCard = ({
  className,
  children,
  title,
  subtitle,
  currentStep,
  totalSteps,
  nextStep,
  previousStep,
  canNext,
  saveAction,
  validationWarning
}) => {
  const containerClass = classNames("step-card-container", className)
  const btnBackClass = classNames("btn", { hidden: currentStep === 1 })
  const btnNextClass = classNames("btn", { canNext })
  const nextClick = canNext
    ? nextStep
    : validationWarning
    ? () => {
        alert(validationWarning)
      }
    : null
  const saveClick = canNext
    ? saveAction
    : validationWarning
    ? () => {
        alert(validationWarning)
      }
    : null

  return (
    <Card className={containerClass}>
      <div className="title-and-instructions">
        {title && <h3>{`${currentStep} - ${title}`}</h3>}
        {subtitle && <small>{subtitle}</small>}
      </div>
      {children}
      <div className="action-area">
        <button className={btnBackClass} type="button" onClick={previousStep}>
          Prev
        </button>
        <ProgressBullets currentStep={currentStep} totalSteps={totalSteps} />
        {saveAction ? (
          <button className={btnNextClass} type="button" onClick={saveClick}>
            Save
          </button>
        ) : (
          <button className={btnNextClass} type="button" onClick={nextClick}>
            Next
          </button>
        )}
      </div>
    </Card>
  )
}

StepCard.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  currentStep: PropTypes.number,
  totalSteps: PropTypes.number,
  nextStep: PropTypes.func,
  previousStep: PropTypes.func,
  canNext: PropTypes.bool,
  saveAction: PropTypes.func,
  validationWarning: PropTypes.string
}

StepCard.defaultProps = {
  className: "",
  children: null,
  title: "",
  subtitle: "",
  currentStep: 1,
  totalSteps: 1,
  nextStep: null,
  previousStep: null,
  canNext: false,
  saveAction: null,
  validationWarning: ""
}

export default StepCard
