import React from "react"
import { Card } from "@winnin/shared.ui.card"
import { Button } from "@winnin/shared.ui.button"
import { Loading } from "@winnin/shared.ui.loading"

import "./index.scss"

const ProjectCard = ({ project, loading, onClick }) => {
  const { title } = project
  return (
    <Card title={title} className="project-card-container">
      {loading ? (
        <Loading />
      ) : (
        <div className="footer-container">
          <Button onClick={onClick} className="fluid">
            Download CSV
          </Button>
        </div>
      )}
    </Card>
  )
}

export default ProjectCard
