import React from "react"
import { Button } from "@winnin/shared.ui.button"
import { Loading } from "@winnin/shared.ui.loading"
import { Header } from "@winnin/shared.ui.header"

import Select from "../../components/Select"
import CardCreator from "../../components/CardCreator"

const languageLabels = {
  all: "Music",
  eng: "English",
  por: "Portuguese",
  spa: "Spanish"
}

const topicSelectStyles = {
  option: provided => ({
    ...provided,
    ".reactSelect-sublabel": {
      height: "0",
      overflow: "hidden",
      transition: "200ms"
    },
    "&:hover .reactSelect-sublabel": {
      height: "14px"
    }
  })
}

const ClassificationHeader = ({
  creator,
  topics,
  selectedTopic,
  selectedPosts,
  handleCancel,
  handleSave,
  setSelectedTopic,
  selectedLanguage,
  languageOptions,
  setSelectedLanguage
}) => {
  if (creator?.error)
    return (
      <div className="ClassificationHeader">
        <p>Error loading creator. Please make sure that the URL is valid.</p>
      </div>
    )

  if (topics?.error)
    return (
      <div className="ClassificationHeader">
        <p>Error loading topics list. Please try again.</p>
      </div>
    )

  if (creator?.loading)
    return (
      <div className="ClassificationHeader">
        <Loading />
      </div>
    )

  if (creator?.data) {
    const isSaveDisabled = !selectedTopic || !selectedPosts?.length
    const isCancelDisabled = !selectedTopic && !selectedPosts?.length
    const selectedPostsCount = selectedPosts?.length
    const topicsOptions = topics?.data
      ?.map(({ title, topicId, language }) => ({
        label: title,
        value: topicId,
        subLabel: languageLabels[language]
      }))
      ?.sort((a, b) => {
        if (a.label > b.label) return 1
        if (a.label < b.label) return -1
        return 0
      })

    return (
      <div className="ClassificationHeader">
        <CardCreator infos={creator?.data} />

        <div className="classification-container">
          <div className="selected-count">
            <Header size="sm">
              {selectedPostsCount === 0
                ? "No"
                : selectedPostsCount?.toLocaleString()}{" "}
              video
              {selectedPostsCount !== 1 && "s"}
            </Header>
            <Header weight="light" size="xxs">
              selected
            </Header>
          </div>

          <Select
            className="language-select"
            label="Select a language"
            placeholder="Language"
            options={languageOptions}
            value={selectedLanguage}
            onChange={setSelectedLanguage}
          />

          <Select
            className="topic-select"
            label="Select a topic"
            placeholder="Topic name"
            options={topicsOptions}
            value={selectedTopic}
            onChange={setSelectedTopic}
            customStyles={topicSelectStyles}
            isLoading={topics?.loading}
          />

          <Button
            disabled={isCancelDisabled}
            onClick={handleCancel}
            appearance="link"
            className="cancel-classification"
          >
            Cancel
          </Button>

          <Button
            disabled={isSaveDisabled}
            onClick={handleSave}
            className="save-classification"
          >
            Save
          </Button>
        </div>
      </div>
    )
  }

  if (creator?.data === null)
    return (
      <div className="ClassificationHeader">
        <p>Creator not found.</p>
      </div>
    )

  return null
}

export default ClassificationHeader
