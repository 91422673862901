import React, { useState } from "react"
import { useMutation } from "@apollo/react-hooks"
import PropTypes from "prop-types"
import { Button } from "@winnin/shared.ui.button"

import CenteredModal from "../CenteredModal"
import Textarea from "../Textarea"
import ToastStore from "../../stores/ToastStore"
import { queryStatus } from "../../consts/queryStatus"
import { EDIT_QUERY_MUTATION } from "./query"
import { client } from "../../ApolloProvider"
import "./index.scss"

const QueryEditModal = ({ history, location: { state } }) => {
  // consts
  const { backgroundPage, queryObj } = state

  // state
  const [queryValue, setQueryValue] = useState(queryObj.query)

  // queries and mutations
  const [editQuery, { data, loading, error }] = useMutation(
    EDIT_QUERY_MUTATION,
    {
      client,
      onCompleted: () => {
        history.push({
          pathname: backgroundPage.pathname,
          state: { refetch: true }
        })

        ToastStore.clearToasts()
        ToastStore.addToast({
          title: "Query updated.",
          id: "edit-query-success"
        })
      },
      onError: error => {
        console.error(`QueryEditModal @ editQuery >>>>> ${error}`)
      }
    }
  )

  // functions
  const closeModal = () => {
    history.push({
      pathname: backgroundPage.pathname
    })
  }

  const handleQueryEdit = () => {
    const variables = {
      queryIdStr: queryObj.id,
      query: queryValue,
      status: queryStatus.updated
    }

    editQuery({ variables })
  }

  // rendering
  const topicTitle = queryObj?.topic?.title
  const isSaveButtonDisabled = !queryValue.length

  return (
    <CenteredModal className="QueryEditModal" closeModal={closeModal}>
      <p>
        You're editing a query in <strong>{topicTitle}</strong>:
      </p>

      <Textarea
        value={queryValue}
        onChange={event => setQueryValue(event.target.value)}
        rows={14}
      />

      <div className="edit-actions">
        <Button disabled={loading} appearance="outline" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          disabled={loading || isSaveButtonDisabled}
          onClick={handleQueryEdit}
        >
          {loading ? "Saving..." : "Save query"}
        </Button>
      </div>

      {(error || data?.updateTopicQueryClassifier?.success === false) && (
        <p className="edit-error">
          Oops! An error occurred. Please, try saving again.
        </p>
      )}
    </CenteredModal>
  )
}

QueryEditModal.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default QueryEditModal
